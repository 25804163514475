import $ from 'jquery'

const onHover = (event) => {
  $(event.currentTarget).addClass('hover')
}

const onHoverOut = (event) => {
  $(event.currentTarget).removeClass('hover')
}

const onClick = (event) => {
  if ($(event.target).prop('type') === 'checkbox') {
    // NOTE: チェックボックスを直接クリックした場合は処理を続けると toggle がダブルのでそのまま離脱
    return
  }
  const $currentTarget = $(event.currentTarget)
  const $checkbox = $currentTarget.closest('tr').find('input[type="checkbox"]')
  $checkbox.prop('checked', !$checkbox.prop('checked'))
}
const setupCheckable = () => {
  $('.js-checkable-row').hover(onHover, onHoverOut)
  $('.js-checkable-row').on('click', onClick)
}

$(document).on('turbo:load', setupCheckable)
