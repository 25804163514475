import $ from 'jquery'
import { findIndex } from 'lodash'

const currentCarouselIndex = () =>
  findIndex($('.js-terms-of-acquisition-recommendation-carousel .carousel-item'), (el) =>
    $(el).hasClass('active'),
  )

const isLastPage = () =>
  currentCarouselIndex() + 1 === $('.js-terms-of-acquisition-recommendation-carousel-item').length

const toggleCarouselButtonsStatus = () => {
  const $prevButton = $('.js-terms-of-acquisition-recommendation-prev')
  const $nextButton = $('.js-terms-of-acquisition-recommendation-next')
  const $closeButton = $('.js-terms-of-acquisition-recommendation-close')
  $('.js-terms-of-acquisition-recommendation-index').text(currentCarouselIndex() + 1)
  $prevButton.toggleClass('hidden', currentCarouselIndex() === 0)
  $nextButton.toggleClass('hidden', isLastPage())
  $closeButton.toggleClass('hidden', !isLastPage())
}

const bsModalShown = (event) => {
  $(event.currentTarget)
    .find('.carousel-item')
    .first()
    .addClass('active')
  toggleCarouselButtonsStatus()
}

const prevRecommendationClicked = (event) => {
  event.preventDefault()
  $('.js-terms-of-acquisition-recommendation-carousel').carousel('prev')
}

const nextRecommendationClicked = (event) => {
  event.preventDefault()
  $('.js-terms-of-acquisition-recommendation-carousel').carousel('next')
}

const onSlidCarousel = (event) => {
  toggleCarouselButtonsStatus()
}

$(document).on('turbo:load', () => {
  $('.js-terms-of-acquisition-recommendation-modal').on('shown.bs.modal', bsModalShown)
  $('.js-terms-of-acquisition-recommendation-carousel').on('slid.bs.carousel', onSlidCarousel)
  $('.js-terms-of-acquisition-recommendation-next').on('click', nextRecommendationClicked)
  $('.js-terms-of-acquisition-recommendation-prev').on('click', prevRecommendationClicked)
})
