import { decamelize } from 'humps'

export const toFormParams = (paramName: string, params: { [s: string]: any }): FormData => {
  const formData = new FormData()
  Object.entries(params).forEach(([key, value]) => {
    if (value instanceof Array) {
      value.forEach((v) => {
        formData.append(`${paramName}[${decamelize(key)}][]`, v)
      })
    } else {
      formData.append(`${paramName}[${decamelize(key)}]`, value)
    }
  })
  return formData
}
