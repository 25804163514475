import { Controller } from '@hotwired/stimulus'
import toastr from 'toastr'

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = { text: String }

  declare textValue: string

  async copy(): Promise<void> {
    await navigator.clipboard.writeText(this.textValue)
    toastr.success('URLをコピーしました')
  }
}
