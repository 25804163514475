const startReadMore = () => {
  const elms = document.querySelectorAll<HTMLDivElement>('.js-read-more')
  elms.forEach(elm => {
    elm.classList.add('is-truncated')
    elm.addEventListener('click', (event: MouseEvent) => {
      elm.classList.toggle('is-truncated')
      elm.classList.toggle('is-not-truncated')
    })
  })
}

document.addEventListener('turbo:load', startReadMore)
