import { Prefecture } from '../types/index'

const prefectures: Record<number, Prefecture> = {
  1: { id: 1, sortKey: 1, name: '北海道', area: '北海道' },
  2: { id: 2, sortKey: 20, name: '青森県', area: '東北' },
  3: { id: 3, sortKey: 30, name: '岩手県', area: '東北' },
  4: { id: 4, sortKey: 4, name: '宮城県', area: '東北' },
  5: { id: 5, sortKey: 50, name: '秋田県', area: '東北' },
  6: { id: 6, sortKey: 60, name: '山形県', area: '東北' },
  7: { id: 7, sortKey: 70, name: '福島県', area: '東北' },
  8: { id: 8, sortKey: 80, name: '茨城県', area: '関東' },
  9: { id: 9, sortKey: 90, name: '栃木県', area: '関東' },
  10: { id: 10, sortKey: 100, name: '群馬県', area: '関東' },
  11: { id: 11, sortKey: 110, name: '埼玉県', area: '関東' },
  12: { id: 12, sortKey: 120, name: '千葉県', area: '関東' },
  13: { id: 13, sortKey: 13, name: '東京都', area: '関東' },
  14: { id: 14, sortKey: 14, name: '神奈川県', area: '関東' },
  15: { id: 15, sortKey: 150, name: '新潟県', area: '中部' },
  16: { id: 16, sortKey: 160, name: '富山県', area: '中部' },
  17: { id: 17, sortKey: 170, name: '石川県', area: '中部' },
  18: { id: 18, sortKey: 180, name: '福井県', area: '中部' },
  19: { id: 19, sortKey: 190, name: '山梨県', area: '中部' },
  20: { id: 20, sortKey: 200, name: '長野県', area: '中部' },
  21: { id: 21, sortKey: 21, name: '岐阜県', area: '中部' },
  22: { id: 22, sortKey: 22, name: '静岡県', area: '中部' },
  23: { id: 23, sortKey: 20, name: '愛知県', area: '中部' },
  24: { id: 24, sortKey: 240, name: '三重県', area: '近畿' },
  25: { id: 25, sortKey: 250, name: '滋賀県', area: '近畿' },
  26: { id: 26, sortKey: 260, name: '京都府', area: '近畿' },
  27: { id: 27, sortKey: 27, name: '大阪府', area: '近畿' },
  28: { id: 28, sortKey: 28, name: '兵庫県', area: '近畿' },
  29: { id: 29, sortKey: 290, name: '奈良県', area: '近畿' },
  30: { id: 30, sortKey: 300, name: '和歌山県', area: '近畿' },
  31: { id: 31, sortKey: 310, name: '鳥取県', area: '中国' },
  32: { id: 32, sortKey: 320, name: '島根県', area: '中国' },
  33: { id: 33, sortKey: 33, name: '岡山県', area: '中国' },
  34: { id: 34, sortKey: 34, name: '広島県', area: '中国' },
  35: { id: 35, sortKey: 350, name: '山口県', area: '中国' },
  36: { id: 36, sortKey: 360, name: '徳島県', area: '四国' },
  37: { id: 37, sortKey: 37, name: '香川県', area: '四国' },
  38: { id: 38, sortKey: 380, name: '愛媛県', area: '四国' },
  39: { id: 39, sortKey: 390, name: '高知県', area: '四国' },
  40: { id: 40, sortKey: 40, name: '福岡県', area: '九州' },
  41: { id: 41, sortKey: 410, name: '佐賀県', area: '九州' },
  42: { id: 42, sortKey: 420, name: '長崎県', area: '九州' },
  43: { id: 43, sortKey: 430, name: '熊本県', area: '九州' },
  44: { id: 44, sortKey: 440, name: '大分県', area: '九州' },
  45: { id: 45, sortKey: 450, name: '宮崎県', area: '九州' },
  46: { id: 46, sortKey: 460, name: '鹿児島県', area: '九州' },
  47: { id: 47, sortKey: 470, name: '沖縄県', area: '九州' },
  100: { id: 100, sortKey: 100, name: 'ヨーロッパ', area: '海外' },
  101: { id: 101, sortKey: 101, name: 'アフリカ', area: '海外' },
  102: { id: 102, sortKey: 102, name: '中東', area: '海外' },
  103: { id: 103, sortKey: 103, name: 'アジア', area: '海外' },
  104: { id: 104, sortKey: 104, name: 'オセアニア', area: '海外' },
  105: { id: 105, sortKey: 105, name: '北米', area: '海外' },
  106: { id: 106, sortKey: 106, name: '中南米', area: '海外' },
}
export default prefectures
