import $ from 'jquery'

const onHover = (event) => {
  $(event.currentTarget).addClass('hover')
}

const onHoverOut = (event) => {
  $(event.currentTarget).removeClass('hover')
}

const onClick = (event) => {
  if (!['A', 'BUTTON', 'INPUT'].includes(event.target.tagName)) {
    const $clickableRowLink = $(event.currentTarget).find('.js-clickable-row-link')
    let url
    if ($clickableRowLink.length > 0) {
      url = $clickableRowLink.attr('href')
    } else {
      url = $(event.currentTarget)
        .find('a')
        .attr('href')
    }
    window.location = url
  }
}
const setupClickable = () => {
  $('.js-clickable-row-simply').hover(onHover, onHoverOut)
  $('.js-clickable-row-simply').on('click', onClick)
  $('.js-clickable-row').hover(onHover, onHoverOut)
  $('.js-clickable-row').on('click', onClick)
}

$(document).on('turbo:load', setupClickable)
