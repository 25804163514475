import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="clickable"
export default class extends Controller {
  static targets = ['excluded']
  declare excludedTargets: HTMLElement[]

  connect(): void {
    this.element.classList.add('tw-cursor-pointer')
  }

  openFirstLink(event: MouseEvent): void {
    if (this.excludedTargets.some((el) => el.contains((event.target as HTMLElement)))) {
      return
    }

    const anchorTag = this.element.querySelector('a') as HTMLAnchorElement
    Turbo.visit(anchorTag.href)
  }
}
