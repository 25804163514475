import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = [
    'body',
    'bodyTemplate',
    'modal',
    'title',
    'titleTemplate',
  ]

  declare readonly bodyTarget: HTMLElement

  declare readonly bodyTemplateTarget: HTMLTemplateElement

  declare readonly modalTarget: HTMLElement

  declare readonly titleTarget: HTMLElement

  declare readonly titleTemplateTarget: HTMLTemplateElement

  connect(): void {
    $(this.modalTarget).on('hidden.bs.modal', () => {
      // NOTE: bootstrap の modal が jquery のイベントしか受け付けないので
      this.clear()
    })
    this.clear()
  }

  show(): void {
    $(this.modalTarget).modal('show')
  }

  hide(event: CustomEvent): void {
    if(event.detail.success) {
      $(this.modalTarget).modal('hide')
    }
  }

  clear(): void {
    this.bodyTarget.innerHTML = this.bodyTemplateTarget.innerHTML
    this.titleTarget.innerHTML = this.titleTemplateTarget.innerHTML
  }
}
