import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="require-on-select-other-option"
export default class extends Controller {
  static targets = ['requiredInput', 'select', 'requiredLabel']

  static values = {
    other: Array,
  }

  declare requiredInputTarget: HTMLTextAreaElement

  declare requiredLabelTarget: HTMLElement

  declare selectTarget: HTMLSelectElement

  declare otherValue: string[]

  toggle(): void {
    const required = this.otherValue.includes(this.selectTarget.value)
    this.requiredInputTarget.required = required
    this.requiredLabelTarget.classList.toggle('tw-hidden', !required)
  }
}
