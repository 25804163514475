const syncRangeFields = (): void => {
  document.querySelectorAll<HTMLElement>('.js-range-fields').forEach((container) => {
    const maxField = container.querySelector<HTMLInputElement>('.js-max-field')
    const minField = container.querySelector<HTMLInputElement>('.js-min-field')

    let originalValue: string
    minField.addEventListener('keydown', () => {
      originalValue = minField.value
    })

    minField.addEventListener('keyup', ({ target }: Event) => {
      const currentValue = (target as HTMLInputElement).value as string
      minField.dispatchEvent(new Event('change'))

      if (originalValue === maxField.value) {
        maxField.value = currentValue
        maxField.dispatchEvent(new Event('change'))
      }
    })
  })
}

document.addEventListener('turbo:load', syncRangeFields)