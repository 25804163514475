import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

// Connects to data-controller="select"
export default class extends Controller {
  connect() {
    $(this.element).select2({ width: '100%' })
  }

  disconnect() {
    $(this.element).select2('destroy')
  }
}
