// NOTE: Facebookみたいに改行に合わせて伸びるフォーム
// https://qiita.com/mizchi/items/c39fd4b532f702c22d18

import $ from 'jquery'

const MAX_HEIGHT = 360

function fitToContent(el, maxHeight) {
  let adjustedHeight = el.clientHeight
  if (!maxHeight || maxHeight > adjustedHeight) {
    adjustedHeight = Math.max(el.scrollHeight, adjustedHeight)
    if (maxHeight) {
      adjustedHeight = Math.min(maxHeight, adjustedHeight)
    }
    if (adjustedHeight > el.clientHeight) {
      $(el).css('height', `${adjustedHeight}px`)
    }
  }
}

$(document).on('turbo:load', () => {
  $(document).on('input init.textarea', 'textarea', ({ currentTarget }) => {
    fitToContent(currentTarget, MAX_HEIGHT)
  })
  $('textarea').trigger('init.textarea')
})
