import $ from 'jquery'

$(document).on('turbo:load', () => {
  $('[data-toggle="popover"]').popover()
})

document.addEventListener('turbo:before-frame-render', (event: Event) => {
  event.target.querySelectorAll('[data-toggle="popover"]').forEach((element) => {
    $(element).popover('hide')
  })
})

document.addEventListener('turbo:frame-render', (event: Event) => {
  event.target.querySelectorAll('[data-toggle="popover"]').forEach((element) => {
    $(element).popover()
  })
})
