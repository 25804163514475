import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-class"
export default class extends Controller {
  static targets = ['item']
  static classes = ['toggle']

  declare readonly itemTargets: HTMLElement[]
  declare readonly toggleClass: string

  connect() {
    if (this.itemTargets.length > 0)
      this.itemTargets[0].classList.add(this.toggleClass)
  }

  toggle(event) {
    this.itemTargets.forEach(itemTarget => {
      itemTarget.classList.remove(this.toggleClass)
    });
    event.currentTarget.classList.add(this.toggleClass)
  }
}
