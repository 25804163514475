import $ from 'jquery'

const getSubindustryInput = () => $($('#subindustry-selection-modal').data('subindustry-target'))

const setupSelectIndustryAction = () => {
  $('.js-subindustry-modal-industry').on('click', (event) => {
    $('.js-subindustry-modal-initial-text').addClass('hidden')
    $('.js-subindustry-modal-industry-container').removeClass('active')
    $(event.currentTarget)
      .find('.js-subindustry-modal-industry-container')
      .addClass('active')
    $('.js-subindustry-modal-subindustry-container').addClass('hidden')
    $(
      `.js-subindustry-modal-subindustry-container[data-industry-id="${event.currentTarget.dataset.industryId}"]`,
    ).removeClass('hidden')
    event.preventDefault()
  })
}

const setupSelectSubindustryAction = () => {
  $('.js-subindustry-modal-checkbox').on('change', (event) => {
    const industryId = $(event.currentTarget)
      .closest('.js-subindustry-modal-subindustry-container')
      .data('industry-id')
    const isIndustrySelected =
      $(
        `.js-subindustry-modal-subindustry-container[data-industry-id="${industryId}"] .js-subindustry-modal-checkbox:checked`,
      ).length > 0
    $(`.js-subindustry-modal-industry-container[data-industry-id="${industryId}"]`).toggleClass(
      'selected',
      isIndustrySelected,
    )
  })
}

const setupApplySubindustryAction = () => {
  $('.js-subindustry-modal-apply').on('click', (event) => {
    const selectedSubindustries = $('.js-subindustry-modal-checkbox:checked').map(
      function checkedValues() {
        return this.value
      },
    )
    const $subindustryInput = getSubindustryInput()
    $subindustryInput.val(selectedSubindustries)
    $subindustryInput.trigger('change')
    $('.js-subindustry-modal').modal('hide')
    event.preventDefault()
  })
}

const setupSubindustryModalState = () => {
  $('.js-subindustry-modal').on('show.bs.modal', () => {
    $('.js-subindustry-modal-checkbox').prop('checked', false)
    $('.js-subindustry-modal-industry-container').removeClass('selected')
    getSubindustryInput()
      .val()
      .forEach((value) => {
        const $selectedSubindustry = $(`.js-subindustry-modal-checkbox[value="${value}"]`)
        $selectedSubindustry.prop('checked', true)
        const industryId = $selectedSubindustry
          .closest('.js-subindustry-modal-subindustry-container')
          .data('industry-id')
        $(`.js-subindustry-modal-industry-container[data-industry-id="${industryId}"]`).addClass(
          'selected',
        )
      })
  })
}

const setupOpenSubindustryModal = () => {
  // NOTE: select2 のプルダウンは表示せず専用modalを表示する
  // ただし選択した項目表示や unselect の動作は利用したい
  // unselect した時には modal を表示させたくないので data に状態を記憶しておく
  $('.js-subindustry-selection-field').on('select2:unselecting', (event) => {
    $(event.currentTarget).data('unselecting', true)
  })
  $('.js-subindustry-selection-field').on('select2:open', (event) => {
    $('.select2-dropdown').remove()
    $(event.currentTarget).select2('close')
    if (!$(event.currentTarget).data('unselecting')) {
      $('#subindustry-selection-modal').modal('show')
    }
    $(event.currentTarget).removeData('unselecting')
  })
}

$(document).on('turbo:load', setupSelectIndustryAction)
$(document).on('turbo:load', setupSelectSubindustryAction)
$(document).on('turbo:load', setupApplySubindustryAction)
$(document).on('turbo:load', setupSubindustryModalState)
$(document).on('turbo:load', setupOpenSubindustryModal)
