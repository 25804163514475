import $ from 'jquery'

const onChange = (event) => {
  if ($(event.currentTarget).val() === 'new') {
    $('.js-new-message-template-form').modal('show')
  }
}

const onInsertButtonClicked = (event) => {
  event.preventDefault()
  const body = $('.js-message-template-select')
    .find('option:selected')
    .data('body')
  if (body != undefined) {
    const $messageBodyTextArea = $('.js-message-body')
    const originalBody = $messageBodyTextArea.val()
    const newBody = originalBody === '' ? body : `${originalBody}\n${body}`
    $messageBodyTextArea.val(newBody)
  }
}

$(
  $(document).on('turbo:load message-template-added', () => {
    $('.js-message-template-select').on('change', onChange)
    $('.js-message-template-insert-link').on('click', onInsertButtonClicked)
  }),
)
