import 'nodelist-foreach-polyfill'
import 'cocoon-js'
import Popover from 'popper.js'
import 'bootstrap'
import 'select2'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import './lib/turbo'
import './lib/turbo-before-cache'
import './lib/turbo-frame-flash'
import './shared/active_storage'
import './shared/analytics'
import './shared/custom-file-input'
import './shared/insert-mention'
import './shared/like'
import './shared/multiple-modals'
import './shared/remote-modals'
import './shared/polyfills'
import './shared/rails-ujs'
import './shared/textarea'
import './shared/checkable-row'
import './shared/clickable-row'
import './shared/enable-select2'
import './shared/term-modal'
import './shared/tom-select'
import './shared/ie'
import './shared/return-submit-disabled'
import './shared/subindustry-selection-modal'
import './shared/enable-yubin-bango'
import './shared/smooth-scroll-config'
import './shared/sync-range-fields'
import './shared/tooltip'
import './shared/dragscroll'
import './shared/flatpickr'
import './shared/popover'
import './shared/readmore.ts' // TODO: こちら消したい
import './shared/read-more.ts'
import './shared/tablesort'
import './shared/message-templates'
import './shared/vue'
import './shared/file-droppable-zone'
import './views/messages'
import './views/admins/sellers'
import './views/admins/deals'
import './views/admins/terms-of-acquisition-recommendations'
import './views/buyers/dashboards'
import './views/buyers/registrations'
import './views/buyers/terms-of-acquisitions'
import './views/buyers/terms_of_acquisitions/deals'
import './views/buyers/plans'
import './views/sellers/bulk_deals'
import './views/sellers/bulk_messages'
import './views/sellers/businesses'
import './views/sellers/dashboards'
import './views/sellers/terms_of_acquisitions'
import './views/sellers/terms-of-acquisition-recommendation'
import './views/sellers/terms_of_acquisitions/deals'
import './views/sellers/crm/ma_businesses'
import './views/sellers/crm/statuses'
import './views/visitors'

import $ from 'jquery'

// view で直接 $ を呼んでいるケースがある
window.jQuery = $
window.$ = $
