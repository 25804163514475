import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="auto-submit"
export default class extends Controller {
  static targets = ['form', 'loading']
  declare readonly formTarget: HTMLFormElement
  declare readonly loadingTarget: HTMLElement
  declare readonly hasLoadingTarget: boolean
  timeout: number

  submit(): void {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 500)

    if(this.hasLoadingTarget) {
      this.loadingTarget.classList.remove('hidden')
    }
  }
}
