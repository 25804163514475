import $ from 'jquery'

$(document).on('turbo:load', () => {
  const $trackRecord = $('.js-acquisition-track-record')
  const $trackRecordDescription = $('.js-acquisition-track-record-description')
  $trackRecord.on('change', (event) => {
    $trackRecordDescription.toggleClass('hidden', event.currentTarget.value !== 'yes')
  })
  $trackRecord.trigger('change')

  const $descriptionTextArea = $('.js-acquisition-track-record-description textarea')
  const $descriptionExample = $('.js-acquisition-track-record-description-example')
  $descriptionTextArea.on('focus', () => {
    $descriptionExample.show()
  })

  $descriptionTextArea.on('blur', () => {
    // 記入例が表示された状態で下のチェックボックスを変更すると 記入例消える→チェック失敗する のを回避
    window.setTimeout(() => {
      $descriptionExample.hide('fast')
    }, 500)
  })

  $descriptionTextArea.trigger('blur')
})

const setupToggleBuyerRemarks = () => {
  $('.js-buyer-purpose').on('change', (event) => {
    $('.js-buyer-purpose-remarks').toggleClass('hidden', event.currentTarget.value !== 'etc')
  })
}

const toggleDomesticFields = () => {
  const isForeign = $('.js-foreign-company:checked').val() === '1'
  $('.js-zip-code, .js-prefecture').toggleClass('hidden', isForeign)
}

const toggleUrlRequired = () => {
  const noUrlCheckbox = document.querySelector('.js-buyers-registration-no-url-checkbox')
  if (!noUrlCheckbox) return

  const input = document.querySelector('.js-buyers-registration-url-input')
  input.required = !noUrlCheckbox.checked
  input.disabled = noUrlCheckbox.checked
}

const onTurboLoad = (event) => {
  toggleUrlRequired()
  setupToggleBuyerRemarks(event)
  toggleDomesticFields()
}

$(document).on('turbo:load', onTurboLoad)
$(document).on('change', '.js-foreign-company', toggleDomesticFields)
$(document).on('change', '.js-buyers-registration-no-url-checkbox', toggleUrlRequired)
