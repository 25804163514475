import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="message-template-selector"
export default class extends Controller {
  static targets = ['select', 'bodyInput']

  declare selectTarget: HTMLSelectElement

  declare bodyInputTarget: HTMLInputElement

  select(): void {
    this.bodyInputTarget.value = this.selectTarget.querySelector<HTMLOptionElement>(
      'option:checked',
    ).value
  }
}
