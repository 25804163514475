const DRAGGING_CLASS = 'bg-yellow-light'
const NORMAL_CLASS = 'bg-white'

const dragOver = (event: DragEvent): void => {
  event.preventDefault()
  event.stopPropagation()
  const currentTarget = event.currentTarget as HTMLDivElement
  currentTarget.classList.add(DRAGGING_CLASS)
  currentTarget.classList.remove(NORMAL_CLASS)
}

const dragLeave = (event: DragEvent): void => {
  const currentTarget = event.currentTarget as HTMLDivElement
  currentTarget.classList.add(NORMAL_CLASS)
  currentTarget.classList.remove(DRAGGING_CLASS)
}

const drag = (event: DragEvent): void => {
  event.preventDefault()
  event.stopPropagation()
  const currentTarget = event.currentTarget as HTMLDivElement

  currentTarget.classList.add(NORMAL_CLASS)
  currentTarget.classList.remove(DRAGGING_CLASS)
  const fileInput = currentTarget.querySelector<HTMLInputElement>('input[type="file"]')
  if (
    event.dataTransfer.files.length === 1 ||
    currentTarget.classList.contains('js-file-droppable-zone-multiple')
  ) {
    fileInput.files = event.dataTransfer.files
  } else {
    alert('ファイルは一つだけアップロード可能です')
  }
}

const setupDroppableFileZone = (): void => {
  const droppables = document.querySelectorAll<HTMLDivElement>('.js-file-droppable-zone')
  droppables.forEach((droppable: HTMLDivElement) => {
    droppable.addEventListener('dragleave', dragLeave)
    droppable.addEventListener('dragover', dragOver)
    droppable.addEventListener('drop', drag)
  })
}

document.addEventListener('turbo:load', setupDroppableFileZone)
