import { Toa } from 'types'
import axiosClient from '../axiosClient'
import { sellersTermsOfAcquisitionLikePath } from '../rails-routes'
import { AxiosResponse } from 'axios'

export async function postLikeToa(toa: Toa): Promise<AxiosResponse<void>> {
  return axiosClient.post(sellersTermsOfAcquisitionLikePath({ termsOfAcquisitionId: toa.id }))
}

export async function deleteLikeToa(toa: Toa): Promise<AxiosResponse<void>> {
  return axiosClient.delete(sellersTermsOfAcquisitionLikePath({ termsOfAcquisitionId: toa.id }))
}
