import TomSelect from 'tom-select';
import { TomSettings } from 'tom-select/dist/types/types';

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.js-tom-select').forEach((selectTag: HTMLSelectElement) => {
    // NOTE: 'false' と文字列で渡しても true になるので注意
    const create = !!(selectTag.dataset['tomSelectCreate'])
    const config : Partial<TomSettings> = {
      create,
      render: {
        option_create: (data, escape) => `<div class="create"><strong>${escape(data.input)}</strong>を追加</div>`,
        no_results: (data, escape) => `<div class="text-muted no-results">選択肢にありません</div>`
      }
    }
    new TomSelect(selectTag, config);
  })
})
