import $ from 'jquery'
import 'smartwizard'

$(document).on('turbo:load', (event) => {
  $('.js-smartwizard').smartWizard({
    keyNavigation: false,
    transitionEffect: 'fade',
    showStepURLhash: false,
    toolbarSettings: {
      toolbarPosition: 'none',
      showPreviousButton: false,
    },
    lang: {
      next: '次へ',
    },
  })
})

const FIRST_STEP_NUMBER = 0
const LAST_STEP_NUMBER = 5

const changeStepState = (stepNumber) => {
  const $saveButton = $('.js-terms-of-acquisitions-wizard-save-button')
  const $nextButton = $('.sw-btn-next')
  const $prevButton = $('.sw-btn-prev')
  if (stepNumber === FIRST_STEP_NUMBER) {
    $prevButton.addClass('hidden')
    $saveButton.prop('disabled', true)
  } else {
    $prevButton.removeClass('hidden')
    $saveButton.prop('disabled', false)
  }
  if (stepNumber === LAST_STEP_NUMBER) {
    $saveButton.text('送信')
    $nextButton.addClass('hidden')
  } else {
    $saveButton.text('一時保存')
    $nextButton.removeClass('hidden')
  }
}

const onShowStep = (_event, _acquisition, stepNumber) => {
  changeStepState(stepNumber)
}

const onLeaveStep = () => {
  const form = $('.js-terms-of-acquisition-wizard-form').get(0)
  if (!form.checkValidity()) {
    form.reportValidity()
    return false
  }
}

const setSubindustrySelection = () => {
  const industryIds = $('.js-subindustry-modal-checkbox:checked').map((_, e) => e.value)
  $('.js-subindustry-selection-field').val(industryIds)
  $('.js-subindustry-selection-field').trigger('change')
}

const setTextValue = (event) => {
  const $currentTarget = $(event.currentTarget)
  const { target } = $currentTarget.data()
  $(target).val($currentTarget.val())
}

const onLoadNewTermsOfAcquisitionPage = () => {
  if ($('.js-terms-of-acquisitions-new-page').length > 0) {
    $('#js-terms-of-acquisition-wizard').modal({ show: true })
    $('.js-modal-transaction-value-max').on('change', setTextValue)
    $('.js-modal-transaction-value-min').on('change', setTextValue)
    $('.js-subindustry-modal-checkbox').on('change', setSubindustrySelection)
    $('.js-modal-terms-of-acquisition-text-value').on('change', setTextValue)
    $('.js-smartwizard').on('leaveStep', onLeaveStep)
    $('.js-smartwizard').on('showStep', onShowStep)
    $('.js-terms-of-acquisition-wizard-next').on('click', (event) => {
      event.preventDefault()
      $('.js-smartwizard').smartWizard('next')
    })

    changeStepState(FIRST_STEP_NUMBER)
  }
}


document.addEventListener('turbo:load', () => {
  $('#js-buyer-terms-of-acquisition-modal').modal({ backdrop: 'static', show: true })
})

$(document).on('turbo:load', onLoadNewTermsOfAcquisitionPage)
