import $ from 'jquery'

const likeOrUnlike = (event) => {
  event.stopPropagation()
  event.preventDefault()
  const $target = $(event.currentTarget)
  const method = $target.data('method').toUpperCase()
  $.ajax({
    url: $(event.currentTarget).attr('href'),
    method,
  })
}

$(document).on('turbo:load', () => {
  $('.js-like-or-unlike').on('click', likeOrUnlike)
})
