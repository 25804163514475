import Rails from '@rails/ujs'
import $ from 'jquery'

Rails.start()

// NOTE: remote: true で redirect が返って来た時に disable のボタンが enable になる問題のワークアラウンド
// https://github.com/rails/rails/issues/29473#issuecomment-332460176
const remoteForm = 'form[method!=get][data-remote=true]'

$(document).on('ajax:complete', remoteForm, (event) => {
  const xhr = event.detail[0]

  if (xhr.responseText.substring(0, 5) == 'Turbo') {
    // This is a turbolink redirect, so re-disable the form until turbolinks
    // fetches the new page.
    Rails.disableElement(event.target)
  } else {
    // Handle if there was a form error... possible solutions in
    // turbolinks/turbolinks#85
  }
})
