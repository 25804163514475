import registerCustomElement from '@sonicgarden/vue-custom-element-rails'
import BootstrapVue, { BModal } from 'bootstrap-vue'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import VTooltipPlugin from 'v-tooltip'

import SubindustrySelectionForm from '../containers/SubindustrySelectionFormWrapper.vue'
import BusinessToaRecommendations from '../containers/BusinessToaRecommendations.vue'
import ToasContainer from '../containers/ToasContainer.vue'
import BulkNdasContainer from '../containers/BulkNdasContainer.vue'
import PrefectureSelectionContainer from '../containers/PrefectureSelectionContainer.vue'
import OwnerSellerFileContainer from '../containers/OwnerSellerFileContainer.vue'
import AcquisitionNeedsMatchingCandidatesContainer from '../containers/AcquisitionNeedsMatchingCandidatesContainer.vue'
import BusinessMatchingCandidatesContainer from '../containers/BusinessMatchingCandidatesContainer.vue'
import MatchStatus from '../components/MatchStatus.vue'
import * as railsRoutes from '../lib/rails-routes'

Vue.use(VueCompositionApi)
Vue.use(VTooltipPlugin)
Vue.use(BootstrapVue)
Vue.prototype.$rails = railsRoutes

registerCustomElement('app-subindustry-selection-form', SubindustrySelectionForm)
registerCustomElement('app-business-toa-recommendations', BusinessToaRecommendations)
registerCustomElement('app-toas-container', ToasContainer)
registerCustomElement('app-b-modal', BModal, { hasSlot: true })
registerCustomElement('app-prefecture-selection-container', PrefectureSelectionContainer)
registerCustomElement('app-bulk-ndas-container', BulkNdasContainer)
registerCustomElement('app-owner-seller-file-container', OwnerSellerFileContainer)
registerCustomElement(
  'app-acquisition-need-matching-candidates-container',
  AcquisitionNeedsMatchingCandidatesContainer,
)
registerCustomElement(
  'app-business-matching-candidates-container',
  BusinessMatchingCandidatesContainer,
)
registerCustomElement('app-match-status', MatchStatus)
