import $ from 'jquery'

const onTurboLoad = () => {
  const $container = $('.js-terms-of-acquisition-recommendations-container')
  $container.on('cocoon:after-insert', (event) => {
    $(event.currentTarget)
      .find('.js-select2')
      .select2()
  })
  $container.on('cocoon:before-remove', (event) => {
    if ($('.js-terms-of-acquisition-recommendations-container .nested-fields').length <= 1) {
      alert('全て削除することはできません')
      event.preventDefault()
    }
  })
}

$(document).on('turbo:load', onTurboLoad)
