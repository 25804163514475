import $ from 'jquery'

$(document).on('click', '.js-read-notices', () => {
  $.ajax({
    type: 'PATCH',
    url: '/sellers/read_mark_notices',
    success: () => {
      $('.js-notice-notification-count').hide()
    },
  })
})

$(document).on('click', '.js-sellers-dashboard-nav-link', (event) => {
  event.preventDefault()
  event.stopPropagation()
  $('.js-sellers-dashboard-nav-link').removeClass('moderate-link')
  $(event.currentTarget).addClass('moderate-link')

  const contentTarget = $(event.currentTarget).data('target')
  $('.js-sellers-tab-contents').addClass('hidden')
  $(contentTarget).removeClass('hidden')
})
