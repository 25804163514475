import $ from 'jquery'
import toastr from 'toastr'
import { Turbo } from '@hotwired/turbo-rails'
import client from '../../../lib/axiosClient'
import { buyersDealMessagesPath, buyersDealsPath } from '../../../lib/rails-routes'

const dealId = (): number => {
  return Number(window.location.href.match(/deals\/([0-9]+)/)[1])
}

const ndaMessageTemplate = (): string => {
  return $('.js-buyer-nda-request-template').data('body')
}

const questionTemplate = (): string => {
  return $('.js-buyer-question-template').data('body')
}

const onReplyTemplateSelectButton = (event): void => {
  event.preventDefault()
  const template = $('.js-buyers-reply-template:checked').val()
  if (template === 'nda_request') {
    $('.js-buyers-reply-template-select-button').prop('disabled', true)
    client
      .post(buyersDealMessagesPath({ dealId: dealId() }), {
        batch: 1,
        message: {
          buyer_template: 'nda_request',
          body: ndaMessageTemplate(),
        },
      })
      .then(async () => {
        await Turbo.visit($('.js-buyers-reply-template-select-button').data('nextUrl'))
        toastr.success('送信しました')
      })
      .catch((e) => {
        toastr.error(e.response.data.errors.join(', '))
      })
    return
  }

  $('.js-message-body').val(questionTemplate)
  $('#js-reply-toolbar').fadeOut()
  $('#js-buyers-select-reply-template-modal').modal('hide')
  $('.js-new-message').removeClass('hidden')
  $('html,body').animate({ scrollTop: $('.js-new-message').offset().top })
}

const onTurboLoad = () => {
  const params = new URLSearchParams(window.location.search)
  if (params.get('show-reply-form') === '1') {
    $('#js-buyers-select-reply-template-modal').modal('show')
  }
}

$(document).on('turbo:load', onTurboLoad)
$(document).on('click', '.js-buyers-reply-template-select-button', onReplyTemplateSelectButton)
