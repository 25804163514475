import $ from 'jquery'

const bulkDealsSubmitClicked = (event) => {
  if ($('.js-bulk-deals-terms-of-acquisitions:checked').length === 0) {
    event.preventDefault()
    event.stopPropagation()
    alert('一つ以上選択して下さい')
  }
}

const onTurboLoad = () => {
  $('.js-bulk-deals-submit').on('click', bulkDealsSubmitClicked)
}

$(document).on('turbo:load', onTurboLoad)
