import $ from 'jquery'
import debounce from 'debounce'
import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)
const isPhone = () => md.phone()

const toggleFiexdFooter = () => {
  const $fixedFooter = $('.js-lp-fixed-footer')
  if ($(document).scrollTop() > 500 && window.parent.screen.width <= 768) {
    $fixedFooter.fadeIn()
  } else {
    $fixedFooter.fadeOut()
  }
}

const hasExitIntentPopupShown = () =>
  sessionStorage && JSON.parse(sessionStorage.getItem('hasExitIntentPopupShown'))
let isUserLikesPage = false

const enableExitIntentPopup = () => {
  let prevPos = 0
  let isScrollingDown = true
  let exitIntentWillShow = false
  let timeoutId
  $(document).on(
    'scroll',
    debounce(() => {
      const currentPos = window.scrollY
      const EXIT_INTENT_POPUP_POSITION = 610

      if (currentPos > EXIT_INTENT_POPUP_POSITION) {
        isUserLikesPage = true
      }
      if (isUserLikesPage && currentPos < prevPos - 100) {
        exitIntentWillShow = true
      }

      if (exitIntentWillShow) {
        // NOTE: 完全に止まるまで待つための処理
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
          if (!hasExitIntentPopupShown()) {
            const $modal = $('#js-exit-intent-popup')
            $modal.modal()
            if ($modal.data('ga-event') !== undefined) {
              window.ga('send', 'event', 'buyers-exit-intent-popup', 'show')
            }
            sessionStorage.setItem('hasExitIntentPopupShown', true)
          }
        }, 500)
      }
      if (prevPos < currentPos) {
        isScrollingDown = true
      } else {
        isScrollingDown = false
      }
      if (isScrollingDown) {
        prevPos = currentPos
      }
    }, 200),
  )
}

const onLoadVisitorTop = () => {
  $(document).on('scroll', debounce(toggleFiexdFooter, 200))
  if (isPhone() && $('#js-exit-intent-popup').length > 0) {
    enableExitIntentPopup()
  }
}

$(document).on('turbo:load', onLoadVisitorTop)
$(document).on('click', '.js-service-point-answer-link', (event) => {
  event.preventDefault()
  const $container = $(event.currentTarget).closest('.js-service-point-answer-container')
  $container.find('.js-service-point-dummy').addClass('hidden')
  $container.find('.js-service-point-answer-body').fadeIn(500)
})
