import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  connect() {
    this.initTomSelect()
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  initTomSelect() {
    if (this.element) {
      this.select = new TomSelect(this.element, {
        render: {
          option_create: (data, escape) => `<div class="create"><strong>${escape(data.input)}</strong>を追加</div>`,
          no_results: (data, escape) => `<div class="text-muted no-results">選択肢にありません</div>`
        }
      })
    }
  }
}
