import $ from 'jquery'

$(() => {
  $('.js-sellers-company-input').on('change', (event, cancelConfirmation) => {
    if (cancelConfirmation) {
      return
    }
    if (!confirm('所属会社が現在の登録情報から変更されますがよろしいですか？')) {
      const $target = $(event.target)
      $target.val($target.data('original'))
      $target.trigger('change', true)
    }
  })
})

const launchRecommendation = () => {
  const $recommendationModal = $('.js-terms-of-acquisition-recommendation-modal')
  if ($recommendationModal.length > 0) {
    $recommendationModal.modal('show')
  }
}
$(document).on('turbo:load', launchRecommendation)
