import $ from 'jquery'

// NOTE: 多重モーダルを実現するために z-index をいじる
// https://stackoverflow.com/questions/19305821/multiple-modals-overlay
$(document).on('turbo:load', () => {
  $(document).on('show.bs.modal', '.modal', (event) => {
    const BASE_Z_INDEX = 1060
    const zIndex = BASE_Z_INDEX + 10 * $('.modal:visible').length
    $(event.currentTarget).css('z-index', zIndex)
    setTimeout(() => {
      $('.modal-backdrop')
        .not('.modal-stack')
        .css('z-index', zIndex - 1)
        .addClass('modal-stack')
    }, 0)
  })
  $(document).on('hidden.bs.modal', '.modal', () => {
    if ($('.modal:visible').length > 0) {
      $(document.body).addClass('modal-open')
    }
  })
})
