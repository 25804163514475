import $ from 'jquery'

const showModalWithRemoteUrl = (event) => {
  event.preventDefault()
  const $currentTarget = $(event.currentTarget)
  $('.js-remote-modal-title').html($currentTarget.data('title'))
  const $modalBody = $('.js-remote-modal-body')
  $modalBody.html(
    '<p class="text-center"><i class="fas fa-spinner fa-spin fa-2x"></i></p><p class="text-center">Loading...</p>',
  )
  $('#js-remote-modal').modal('show')
  const url = $currentTarget.data('url')
  $modalBody.load(url, () => {
    $modalBody.trigger('turbo:load')
  })
}

export default showModalWithRemoteUrl
