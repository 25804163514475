import { Controller } from "@hotwired/stimulus"
import { StrategyProps, Textcomplete } from '@textcomplete/core'
import { TextareaEditor } from '@textcomplete/textarea'

// Connects to data-controller="textcomplete"
export default class extends Controller {

  textcomplete: Textcomplete

  connect() {
    const elm = this.element as HTMLTextAreaElement
    const editor = new TextareaEditor(elm)
    const candidates = JSON.parse(elm.dataset.textcompleteCandidates)
    const strategy: StrategyProps = {
      id: 'mention',
      match: /(^|\s)@([\d.A-Za-zぁ-ヶー一-鿆０-９Ａ-Ｚａ-ｚｦ-ﾟ-]*)$/,
      index: 2,
      search: async (term, callback): Promise<void> => {
        callback(candidates.filter((candidate: string) => candidate.includes(term)))
      },
      template: (term) => `<span>&nbsp;${term}</span>`,
      replace: (result): string => `$1@${result} `,
    }

    this.textcomplete = new Textcomplete(editor, [strategy], { dropdown: { maxCount: 100 } })
  }

  disconnect() {
    this.textcomplete.destroy()
  }
}
