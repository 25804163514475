// Don't edit manually. `rake js:routes` generates this file.
type Value = string | number
type Params<Keys extends string> = { [key in Keys]: Value } & Record<string, Value>
function process(route: string, params: Record<string, Value> | undefined, keys: string[]): string {
  if (!params) return route
  var query: string[] = [];
  for (var param in params) if (params.hasOwnProperty(param)) {
    if (keys.indexOf(param) === -1) {
      query.push(param + "=" + encodeURIComponent(params[param].toString()));
    }
  }
  return query.length ? route + "?" + query.join("&") : route;
}

export function newBuyerSessionPath(params?: Record<string, Value>) { return process('/buyers/sign_in', params, []); }
export function buyerSessionPath(params?: Record<string, Value>) { return process('/buyers/sign_in', params, []); }
export function destroyBuyerSessionPath(params?: Record<string, Value>) { return process('/buyers/sign_out', params, []); }
export function newBuyerPasswordPath(params?: Record<string, Value>) { return process('/buyers/password/new', params, []); }
export function editBuyerPasswordPath(params?: Record<string, Value>) { return process('/buyers/password/edit', params, []); }
export function buyerPasswordPath(params?: Record<string, Value>) { return process('/buyers/password', params, []); }
export function cancelBuyerRegistrationPath(params?: Record<string, Value>) { return process('/buyers/cancel', params, []); }
export function newBuyerRegistrationPath(params?: Record<string, Value>) { return process('/buyers/sign_up', params, []); }
export function editBuyerRegistrationPath(params?: Record<string, Value>) { return process('/buyers/edit', params, []); }
export function buyerRegistrationPath(params?: Record<string, Value>) { return process('/buyers', params, []); }
export function newBuyerConfirmationPath(params?: Record<string, Value>) { return process('/buyers/confirmation/new', params, []); }
export function buyerConfirmationPath(params?: Record<string, Value>) { return process('/buyers/confirmation', params, []); }
export function acceptBuyerInvitationPath(params?: Record<string, Value>) { return process('/buyers/invitation/accept', params, []); }
export function removeBuyerInvitationPath(params?: Record<string, Value>) { return process('/buyers/invitation/remove', params, []); }
export function newBuyerInvitationPath(params?: Record<string, Value>) { return process('/buyers/invitation/new', params, []); }
export function buyerInvitationPath(params?: Record<string, Value>) { return process('/buyers/invitation', params, []); }
export function newSellerPasswordPath(params?: Record<string, Value>) { return process('/sellers/password/new', params, []); }
export function editSellerPasswordPath(params?: Record<string, Value>) { return process('/sellers/password/edit', params, []); }
export function sellerPasswordPath(params?: Record<string, Value>) { return process('/sellers/password', params, []); }
export function cancelSellerRegistrationPath(params?: Record<string, Value>) { return process('/sellers/cancel', params, []); }
export function newSellerRegistrationPath(params?: Record<string, Value>) { return process('/sellers/sign_up', params, []); }
export function editSellerRegistrationPath(params?: Record<string, Value>) { return process('/sellers/edit', params, []); }
export function sellerRegistrationPath(params?: Record<string, Value>) { return process('/sellers', params, []); }
export function newSellerConfirmationPath(params?: Record<string, Value>) { return process('/sellers/confirmation/new', params, []); }
export function sellerConfirmationPath(params?: Record<string, Value>) { return process('/sellers/confirmation', params, []); }
export function acceptSellerInvitationPath(params?: Record<string, Value>) { return process('/sellers/invitation/accept', params, []); }
export function removeSellerInvitationPath(params?: Record<string, Value>) { return process('/sellers/invitation/remove', params, []); }
export function newSellerInvitationPath(params?: Record<string, Value>) { return process('/sellers/invitation/new', params, []); }
export function sellerInvitationPath(params?: Record<string, Value>) { return process('/sellers/invitation', params, []); }
export function newSellerSessionPath(params?: Record<string, Value>) { return process('/sellers/sign_in', params, []); }
export function sellerSessionPath(params?: Record<string, Value>) { return process('/sellers/sign_in', params, []); }
export function destroySellerSessionPath(params?: Record<string, Value>) { return process('/sellers/sign_out', params, []); }
export function editAdminsDealStatusPath(params: Params<'dealId'>) { return process('/admins/deals/' + params.dealId + '/status/edit', params, ['dealId']); }
export function adminsDealStatusPath(params: Params<'dealId'>) { return process('/admins/deals/' + params.dealId + '/status', params, ['dealId']); }
export function adminsDealStatusChangeEventsPath(params: Params<'dealId'>) { return process('/admins/deals/' + params.dealId + '/status_change_events', params, ['dealId']); }
export function newAdminsDealStatusChangeEventPath(params: Params<'dealId'>) { return process('/admins/deals/' + params.dealId + '/status_change_events/new', params, ['dealId']); }
export function adminsSellerOverviewRootPath(params: Params<'sellerId'>) { return process('/admins/sellers/' + params.sellerId + '/overview', params, ['sellerId']); }
export function adminsSellerOverviewBusinessDealsPath(params: Params<'sellerId' | 'businessId'>) { return process('/admins/sellers/' + params.sellerId + '/overview/businesses/' + params.businessId + '/deals', params, ['sellerId','businessId']); }
export function adminsSellerOverviewBusinessesPath(params: Params<'sellerId'>) { return process('/admins/sellers/' + params.sellerId + '/overview/businesses', params, ['sellerId']); }
export function adminsBusinessSelectableStatusPath(params: Params<'businessId'>) { return process('/admins/businesses/' + params.businessId + '/selectable_status', params, ['businessId']); }
export function buyersDashboardPath(params?: Record<string, Value>) { return process('/buyers/dashboard', params, []); }
export function buyersMafolovaNdaStancePath(params?: Record<string, Value>) { return process('/buyers/mafolova_nda_stance', params, []); }
export function sortBuyersNdaApproverPath(params: Params<'id'>) { return process('/buyers/nda_approvers/' + params.id + '/sort', params, ['id']); }
export function buyersNdaApproversPath(params?: Record<string, Value>) { return process('/buyers/nda_approvers', params, []); }
export function editBuyersNdaApproverPath(params: Params<'id'>) { return process('/buyers/nda_approvers/' + params.id + '/edit', params, ['id']); }
export function buyersNdaApproverPath(params: Params<'id'>) { return process('/buyers/nda_approvers/' + params.id + '', params, ['id']); }
export function editBuyersNdaSettingPath(params?: Record<string, Value>) { return process('/buyers/nda_setting/edit', params, []); }
export function buyersNdaSettingPath(params?: Record<string, Value>) { return process('/buyers/nda_setting', params, []); }
export function buyersServicePricePath(params?: Record<string, Value>) { return process('/buyers/service_price', params, []); }
export function buyersTermAgreementPath(params: Params<'termId'>) { return process('/buyers/terms/' + params.termId + '/agreement', params, ['termId']); }
export function editBuyersSignupStep1Path(params?: Record<string, Value>) { return process('/buyers/signup/step1/edit', params, []); }
export function buyersSignupStep1Path(params?: Record<string, Value>) { return process('/buyers/signup/step1', params, []); }
export function editBuyersSignupStep2Path(params?: Record<string, Value>) { return process('/buyers/signup/step2/edit', params, []); }
export function buyersSignupStep2Path(params?: Record<string, Value>) { return process('/buyers/signup/step2', params, []); }
export function editBuyersSignupStep3Path(params?: Record<string, Value>) { return process('/buyers/signup/step3/edit', params, []); }
export function buyersSignupStep3Path(params?: Record<string, Value>) { return process('/buyers/signup/step3', params, []); }
export function editBuyersSignupStep4Path(params?: Record<string, Value>) { return process('/buyers/signup/step4/edit', params, []); }
export function buyersSignupStep4Path(params?: Record<string, Value>) { return process('/buyers/signup/step4', params, []); }
export function editBuyersSignupConfirmingPath(params?: Record<string, Value>) { return process('/buyers/signup/confirming/edit', params, []); }
export function buyersSignupConfirmingPath(params?: Record<string, Value>) { return process('/buyers/signup/confirming', params, []); }
export function buyersResendInvitationsPath(params?: Record<string, Value>) { return process('/buyers/resend_invitations', params, []); }
export function newBuyersResendInvitationPath(params?: Record<string, Value>) { return process('/buyers/resend_invitations/new', params, []); }
export function buyersBusinessesPath(params?: Record<string, Value>) { return process('/buyers/businesses', params, []); }
export function buyersBusinessPath(params: Params<'id'>) { return process('/buyers/businesses/' + params.id + '', params, ['id']); }
export function buyersUnconfirmedDealsPath(params?: Record<string, Value>) { return process('/buyers/unconfirmed_deals', params, []); }
export function buyersDealSkipPath(params: Params<'dealId'>) { return process('/buyers/deals/' + params.dealId + '/skip', params, ['dealId']); }
export function buyersDealsPath(params?: Record<string, Value>) { return process('/buyers/deals', params, []); }
export function buyersTermsOfAcquisitionTouchPath(params: Params<'termsOfAcquisitionId'>) { return process('/buyers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/touch', params, ['termsOfAcquisitionId']); }
export function buyersTermsOfAcquisitionDealReopeningPath(params: Params<'termsOfAcquisitionId' | 'dealId'>) { return process('/buyers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/deals/' + params.dealId + '/reopening', params, ['termsOfAcquisitionId','dealId']); }
export function buyersTermsOfAcquisitionDealsPath(params: Params<'termsOfAcquisitionId'>) { return process('/buyers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/deals', params, ['termsOfAcquisitionId']); }
export function buyersTermsOfAcquisitionDealPath(params: Params<'termsOfAcquisitionId' | 'id'>) { return process('/buyers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/deals/' + params.id + '', params, ['termsOfAcquisitionId','id']); }
export function buyersTermsOfAcquisitionWantedStatusPath(params: Params<'termsOfAcquisitionId'>) { return process('/buyers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/wanted_status', params, ['termsOfAcquisitionId']); }
export function buyersTermsOfAcquisitionsPath(params?: Record<string, Value>) { return process('/buyers/terms_of_acquisitions', params, []); }
export function newBuyersTermsOfAcquisitionPath(params?: Record<string, Value>) { return process('/buyers/terms_of_acquisitions/new', params, []); }
export function editBuyersTermsOfAcquisitionPath(params: Params<'id'>) { return process('/buyers/terms_of_acquisitions/' + params.id + '/edit', params, ['id']); }
export function buyersTermsOfAcquisitionPath(params: Params<'id'>) { return process('/buyers/terms_of_acquisitions/' + params.id + '', params, ['id']); }
export function buyersDealMessagesPath(params: Params<'dealId'>) { return process('/buyers/deals/' + params.dealId + '/messages', params, ['dealId']); }
export function buyersDealMessagePath(params: Params<'dealId' | 'id'>) { return process('/buyers/deals/' + params.dealId + '/messages/' + params.id + '', params, ['dealId','id']); }
export function buyersDealArchivesPath(params: Params<'dealId'>) { return process('/buyers/deals/' + params.dealId + '/archives', params, ['dealId']); }
export function buyersDealArchivePath(params: Params<'dealId' | 'id'>) { return process('/buyers/deals/' + params.dealId + '/archives/' + params.id + '', params, ['dealId','id']); }
export function buyersArchivedDealsPath(params?: Record<string, Value>) { return process('/buyers/archived_deals', params, []); }
export function buyersAgreedTermPath(params?: Record<string, Value>) { return process('/buyers/agreed_term', params, []); }
export function sellersCrmRootPath(params?: Record<string, Value>) { return process('/sellers/crm', params, []); }
export function downloadSellersCrmCusCompaniesPath(params?: Record<string, Value>) { return process('/sellers/crm/cus_companies/download', params, []); }
export function sellersCrmCusCompanyCommentsPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/comments', params, ['cusCompanyId']); }
export function editSellersCrmCusCompanyCommentPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/comments/' + params.id + '/edit', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyCommentPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/comments/' + params.id + '', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyBusinessCustomerAcquisitionNeedLongListPath(params: Params<'cusCompanyId' | 'businessId' | 'customerAcquisitionNeedId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/customer_acquisition_needs/' + params.customerAcquisitionNeedId + '/long_list', params, ['cusCompanyId','businessId','customerAcquisitionNeedId']); }
export function sellersCrmCusCompanyBusinessCustomerAcquisitionNeedsPath(params: Params<'cusCompanyId' | 'businessId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/customer_acquisition_needs', params, ['cusCompanyId','businessId']); }
export function sellersCrmCusCompanyBusinessTermsOfAcquisitionsPath(params: Params<'cusCompanyId' | 'businessId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/terms_of_acquisitions', params, ['cusCompanyId','businessId']); }
export function newSellersCrmCusCompanyBusinessMatchDealPath(params: Params<'cusCompanyId' | 'businessId' | 'matchId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/matches/' + params.matchId + '/deal/new', params, ['cusCompanyId','businessId','matchId']); }
export function sellersCrmCusCompanyBusinessMatchDealPath(params: Params<'cusCompanyId' | 'businessId' | 'matchId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/matches/' + params.matchId + '/deal', params, ['cusCompanyId','businessId','matchId']); }
export function sellersCrmCusCompanyBusinessMatchesPath(params: Params<'cusCompanyId' | 'businessId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/matches', params, ['cusCompanyId','businessId']); }
export function newSellersCrmCusCompanyBusinessMatchPath(params: Params<'cusCompanyId' | 'businessId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/matches/new', params, ['cusCompanyId','businessId']); }
export function editSellersCrmCusCompanyBusinessMatchPath(params: Params<'cusCompanyId' | 'businessId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/matches/' + params.id + '/edit', params, ['cusCompanyId','businessId','id']); }
export function sellersCrmCusCompanyBusinessMatchPath(params: Params<'cusCompanyId' | 'businessId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.businessId + '/matches/' + params.id + '', params, ['cusCompanyId','businessId','id']); }
export function sellersCrmCusCompanyBusinessesPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses', params, ['cusCompanyId']); }
export function newSellersCrmCusCompanyBusinessPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/new', params, ['cusCompanyId']); }
export function editSellersCrmCusCompanyBusinessPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.id + '/edit', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyBusinessPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/businesses/' + params.id + '', params, ['cusCompanyId','id']); }
export function copySellersCrmCusCompanyMaBusinessPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/ma_businesses/' + params.id + '/copy', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyMaBusinessesPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/ma_businesses', params, ['cusCompanyId']); }
export function sellersCrmCusCompanyMaBusinessPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/ma_businesses/' + params.id + '', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyCustomerAcquisitionNeedBusinessesPath(params: Params<'cusCompanyId' | 'customerAcquisitionNeedId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs/' + params.customerAcquisitionNeedId + '/businesses', params, ['cusCompanyId','customerAcquisitionNeedId']); }
export function sellersCrmCusCompanyCustomerAcquisitionNeedMatchesPath(params: Params<'cusCompanyId' | 'customerAcquisitionNeedId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs/' + params.customerAcquisitionNeedId + '/matches', params, ['cusCompanyId','customerAcquisitionNeedId']); }
export function newSellersCrmCusCompanyCustomerAcquisitionNeedMatchPath(params: Params<'cusCompanyId' | 'customerAcquisitionNeedId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs/' + params.customerAcquisitionNeedId + '/matches/new', params, ['cusCompanyId','customerAcquisitionNeedId']); }
export function editSellersCrmCusCompanyCustomerAcquisitionNeedMatchPath(params: Params<'cusCompanyId' | 'customerAcquisitionNeedId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs/' + params.customerAcquisitionNeedId + '/matches/' + params.id + '/edit', params, ['cusCompanyId','customerAcquisitionNeedId','id']); }
export function sellersCrmCusCompanyCustomerAcquisitionNeedMatchPath(params: Params<'cusCompanyId' | 'customerAcquisitionNeedId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs/' + params.customerAcquisitionNeedId + '/matches/' + params.id + '', params, ['cusCompanyId','customerAcquisitionNeedId','id']); }
export function sellersCrmCusCompanyCustomerAcquisitionNeedsPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs', params, ['cusCompanyId']); }
export function newSellersCrmCusCompanyCustomerAcquisitionNeedPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs/new', params, ['cusCompanyId']); }
export function editSellersCrmCusCompanyCustomerAcquisitionNeedPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs/' + params.id + '/edit', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyCustomerAcquisitionNeedPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customer_acquisition_needs/' + params.id + '', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyCusCompanyAssignmentsPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/cus_company_assignments', params, ['cusCompanyId']); }
export function sellersCrmCusCompanyCusCompanyAssignmentPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/cus_company_assignments/' + params.id + '', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyCustomersPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customers', params, ['cusCompanyId']); }
export function newSellersCrmCusCompanyCustomerPath(params: Params<'cusCompanyId'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customers/new', params, ['cusCompanyId']); }
export function editSellersCrmCusCompanyCustomerPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customers/' + params.id + '/edit', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompanyCustomerPath(params: Params<'cusCompanyId' | 'id'>) { return process('/sellers/crm/cus_companies/' + params.cusCompanyId + '/customers/' + params.id + '', params, ['cusCompanyId','id']); }
export function sellersCrmCusCompaniesPath(params?: Record<string, Value>) { return process('/sellers/crm/cus_companies', params, []); }
export function newSellersCrmCusCompanyPath(params?: Record<string, Value>) { return process('/sellers/crm/cus_companies/new', params, []); }
export function editSellersCrmCusCompanyPath(params: Params<'id'>) { return process('/sellers/crm/cus_companies/' + params.id + '/edit', params, ['id']); }
export function sellersCrmCusCompanyPath(params: Params<'id'>) { return process('/sellers/crm/cus_companies/' + params.id + '', params, ['id']); }
export function sellersCrmCustomerAcquisitionNeedsPath(params?: Record<string, Value>) { return process('/sellers/crm/customer_acquisition_needs', params, []); }
export function sellersCrmCustomerFileLogPath(params: Params<'customerFileId'>) { return process('/sellers/crm/customer_files/' + params.customerFileId + '/log', params, ['customerFileId']); }
export function sellersCrmCustomerFilesPath(params?: Record<string, Value>) { return process('/sellers/crm/customer_files', params, []); }
export function sellersCrmMatchingBusinessCommentsPath(params: Params<'matchingBusinessId'>) { return process('/sellers/crm/matching_businesses/' + params.matchingBusinessId + '/comments', params, ['matchingBusinessId']); }
export function editSellersCrmMatchingBusinessCommentPath(params: Params<'matchingBusinessId' | 'id'>) { return process('/sellers/crm/matching_businesses/' + params.matchingBusinessId + '/comments/' + params.id + '/edit', params, ['matchingBusinessId','id']); }
export function sellersCrmMatchingBusinessCommentPath(params: Params<'matchingBusinessId' | 'id'>) { return process('/sellers/crm/matching_businesses/' + params.matchingBusinessId + '/comments/' + params.id + '', params, ['matchingBusinessId','id']); }
export function sellersCrmMatchingBusinessesPath(params?: Record<string, Value>) { return process('/sellers/crm/matching_businesses', params, []); }
export function sellersCrmMatchingBusinessPath(params: Params<'id'>) { return process('/sellers/crm/matching_businesses/' + params.id + '', params, ['id']); }
export function sellersCrmSellersPath(params?: Record<string, Value>) { return process('/sellers/crm/sellers', params, []); }
export function sellersCrmSellerPath(params: Params<'id'>) { return process('/sellers/crm/sellers/' + params.id + '', params, ['id']); }
export function sellersCrmTagsPath(params?: Record<string, Value>) { return process('/sellers/crm/tags', params, []); }
export function newSellersCrmTagPath(params?: Record<string, Value>) { return process('/sellers/crm/tags/new', params, []); }
export function editSellersCrmTagPath(params: Params<'id'>) { return process('/sellers/crm/tags/' + params.id + '/edit', params, ['id']); }
export function sellersCrmTagPath(params: Params<'id'>) { return process('/sellers/crm/tags/' + params.id + '', params, ['id']); }
export function sortSellersCrmMatchStatusPath(params: Params<'id'>) { return process('/sellers/crm/match_statuses/' + params.id + '/sort', params, ['id']); }
export function sellersCrmMatchStatusesPath(params?: Record<string, Value>) { return process('/sellers/crm/match_statuses', params, []); }
export function sellersCrmMatchStatusPath(params: Params<'id'>) { return process('/sellers/crm/match_statuses/' + params.id + '', params, ['id']); }
export function sellersCrmBusinessesPath(params?: Record<string, Value>) { return process('/sellers/crm/businesses', params, []); }
export function sellersBulkDealsPath(params?: Record<string, Value>) { return process('/sellers/bulk_deals', params, []); }
export function sellersBulkMessagesPath(params?: Record<string, Value>) { return process('/sellers/bulk_messages', params, []); }
export function newSellersBulkMessagePath(params?: Record<string, Value>) { return process('/sellers/bulk_messages/new', params, []); }
export function sellersBulkNdasPath(params?: Record<string, Value>) { return process('/sellers/bulk_ndas', params, []); }
export function sellersDashboardPath(params?: Record<string, Value>) { return process('/sellers/dashboard', params, []); }
export function sortSellersNdaApproverPath(params: Params<'id'>) { return process('/sellers/nda_approvers/' + params.id + '/sort', params, ['id']); }
export function sellersNdaApproversPath(params?: Record<string, Value>) { return process('/sellers/nda_approvers', params, []); }
export function editSellersNdaApproverPath(params: Params<'id'>) { return process('/sellers/nda_approvers/' + params.id + '/edit', params, ['id']); }
export function sellersNdaApproverPath(params: Params<'id'>) { return process('/sellers/nda_approvers/' + params.id + '', params, ['id']); }
export function sellersResendInvitationsPath(params?: Record<string, Value>) { return process('/sellers/resend_invitations', params, []); }
export function newSellersResendInvitationPath(params?: Record<string, Value>) { return process('/sellers/resend_invitations/new', params, []); }
export function sellersTermAgreementPath(params: Params<'termId'>) { return process('/sellers/terms/' + params.termId + '/agreement', params, ['termId']); }
export function sellersAgreedTermPath(params?: Record<string, Value>) { return process('/sellers/agreed_term', params, []); }
export function sellersMessageTemplatesPath(params?: Record<string, Value>) { return process('/sellers/message_templates', params, []); }
export function newSellersMessageTemplatePath(params?: Record<string, Value>) { return process('/sellers/message_templates/new', params, []); }
export function editSellersMessageTemplatePath(params: Params<'id'>) { return process('/sellers/message_templates/' + params.id + '/edit', params, ['id']); }
export function sellersMessageTemplatePath(params: Params<'id'>) { return process('/sellers/message_templates/' + params.id + '', params, ['id']); }
export function sellersNoticesPath(params?: Record<string, Value>) { return process('/sellers/notices', params, []); }
export function sellersReadMarkNoticesPath(params?: Record<string, Value>) { return process('/sellers/read_mark_notices', params, []); }
export function sellersBusinessSelectableStatusPath(params: Params<'businessId'>) { return process('/sellers/businesses/' + params.businessId + '/selectable_status', params, ['businessId']); }
export function sellersBusinessDealDealStatusChangeEventsPath(params: Params<'businessId' | 'dealId'>) { return process('/sellers/businesses/' + params.businessId + '/deals/' + params.dealId + '/deal_status_change_events', params, ['businessId','dealId']); }
export function sellersBusinessDealsPath(params: Params<'businessId'>) { return process('/sellers/businesses/' + params.businessId + '/deals', params, ['businessId']); }
export function sellersBusinessTermsOfAcquisitionsPath(params: Params<'businessId'>) { return process('/sellers/businesses/' + params.businessId + '/terms_of_acquisitions', params, ['businessId']); }
export function sellersBusinessCopiesPath(params: Params<'businessId'>) { return process('/sellers/businesses/' + params.businessId + '/copies', params, ['businessId']); }
export function sellersBusinessesPath(params?: Record<string, Value>) { return process('/sellers/businesses', params, []); }
export function newSellersBusinessPath(params?: Record<string, Value>) { return process('/sellers/businesses/new', params, []); }
export function editSellersBusinessPath(params: Params<'id'>) { return process('/sellers/businesses/' + params.id + '/edit', params, ['id']); }
export function sellersBusinessPath(params: Params<'id'>) { return process('/sellers/businesses/' + params.id + '', params, ['id']); }
export function sellersTermsOfAcquisitionRecommendationDismissPath(params: Params<'termsOfAcquisitionRecommendationId'>) { return process('/sellers/terms_of_acquisition_recommendations/' + params.termsOfAcquisitionRecommendationId + '/dismiss', params, ['termsOfAcquisitionRecommendationId']); }
export function sellersTermsOfAcquisitionRecommendationsPath(params?: Record<string, Value>) { return process('/sellers/terms_of_acquisition_recommendations', params, []); }
export function sellersOverviewRootPath(params?: Record<string, Value>) { return process('/sellers/overview', params, []); }
export function sellersOverviewBusinessDealsPath(params: Params<'businessId'>) { return process('/sellers/overview/businesses/' + params.businessId + '/deals', params, ['businessId']); }
export function sellersOverviewBusinessesPath(params?: Record<string, Value>) { return process('/sellers/overview/businesses', params, []); }
export function sellersLikesPath(params?: Record<string, Value>) { return process('/sellers/terms_of_acquisitions/likes', params, []); }
export function sellersNotificationRequestPath(params?: Record<string, Value>) { return process('/sellers/terms_of_acquisitions/notification_request', params, []); }
export function sellersTermsOfAcquisitionDealsPath(params: Params<'termsOfAcquisitionId'>) { return process('/sellers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/deals', params, ['termsOfAcquisitionId']); }
export function newSellersTermsOfAcquisitionDealPath(params: Params<'termsOfAcquisitionId'>) { return process('/sellers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/deals/new', params, ['termsOfAcquisitionId']); }
export function sellersTermsOfAcquisitionDealPath(params: Params<'termsOfAcquisitionId' | 'id'>) { return process('/sellers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/deals/' + params.id + '', params, ['termsOfAcquisitionId','id']); }
export function sellersTermsOfAcquisitionLikePath(params: Params<'termsOfAcquisitionId'>) { return process('/sellers/terms_of_acquisitions/' + params.termsOfAcquisitionId + '/like', params, ['termsOfAcquisitionId']); }
export function sellersTermsOfAcquisitionsPath(params?: Record<string, Value>) { return process('/sellers/terms_of_acquisitions', params, []); }
export function sellersTermsOfAcquisitionPath(params: Params<'id'>) { return process('/sellers/terms_of_acquisitions/' + params.id + '', params, ['id']); }
export function sellersDealMessagesPath(params: Params<'dealId'>) { return process('/sellers/deals/' + params.dealId + '/messages', params, ['dealId']); }
export function sellersDealMessagePath(params: Params<'dealId' | 'id'>) { return process('/sellers/deals/' + params.dealId + '/messages/' + params.id + '', params, ['dealId','id']); }
export function sellersDealNdaPath(params: Params<'dealId'>) { return process('/sellers/deals/' + params.dealId + '/nda', params, ['dealId']); }
export function sellersDealStatusPath(params: Params<'dealId'>) { return process('/sellers/deals/' + params.dealId + '/status', params, ['dealId']); }
export function sellersDealArchivesPath(params: Params<'dealId'>) { return process('/sellers/deals/' + params.dealId + '/archives', params, ['dealId']); }
export function sellersDealArchivePath(params: Params<'dealId' | 'id'>) { return process('/sellers/deals/' + params.dealId + '/archives/' + params.id + '', params, ['dealId','id']); }
export function sellersDealsPath(params?: Record<string, Value>) { return process('/sellers/deals', params, []); }
export function sellersDealPath(params: Params<'id'>) { return process('/sellers/deals/' + params.id + '', params, ['id']); }
export function sellersMessageRemindMailsPath(params: Params<'messageId'>) { return process('/sellers/messages/' + params.messageId + '/remind_mails', params, ['messageId']); }
export function sellersArchivedDealsPath(params?: Record<string, Value>) { return process('/sellers/archived_deals', params, []); }
export function sellersManagersDealsPath(params?: Record<string, Value>) { return process('/sellers/managers/deals', params, []); }
export function sellersManagersBusinessesPath(params?: Record<string, Value>) { return process('/sellers/managers/businesses', params, []); }
export function newSellersMfaPath(params?: Record<string, Value>) { return process('/sellers/mfa/new', params, []); }
export function sellersMfaPath(params?: Record<string, Value>) { return process('/sellers/mfa', params, []); }
export function editSellersPendingOwnersProfilePath(params?: Record<string, Value>) { return process('/sellers/pending_owners/profile/edit', params, []); }
export function sellersPendingOwnersProfilePath(params?: Record<string, Value>) { return process('/sellers/pending_owners/profile', params, []); }
export function sellersPendingOwnersWaitingPath(params?: Record<string, Value>) { return process('/sellers/pending_owners/waiting', params, []); }
export function thanksVisitorsAdviserRequestsPath(params?: Record<string, Value>) { return process('/visitors/adviser_requests/thanks', params, []); }
export function visitorsAdviserRequestsPath(params?: Record<string, Value>) { return process('/visitors/adviser_requests', params, []); }
export function newVisitorsAdviserRequestPath(params?: Record<string, Value>) { return process('/visitors/adviser_requests/new', params, []); }
export function visitorsExternalTransmissionPath(params?: Record<string, Value>) { return process('/visitors/external_transmission', params, []); }
export function thanksVisitorsSeminarApplicantsPath(params?: Record<string, Value>) { return process('/visitors/seminar/applicants/thanks', params, []); }
export function visitorsSeminarApplicantsPath(params?: Record<string, Value>) { return process('/visitors/seminar/applicants', params, []); }
export function newVisitorsSeminarApplicantPath(params?: Record<string, Value>) { return process('/visitors/seminar/applicants/new', params, []); }
export function visitorsSeminarPath(params?: Record<string, Value>) { return process('/visitors/seminar', params, []); }
export function eduteVisitorsInterviewsPath(params?: Record<string, Value>) { return process('/visitors/interviews/edute', params, []); }
export function tublockVisitorsInterviewsPath(params?: Record<string, Value>) { return process('/visitors/interviews/tublock', params, []); }
export function visitorsOwnerSellersPath(params?: Record<string, Value>) { return process('/visitors/owner_sellers', params, []); }
export function newVisitorsOwnerSellerPath(params?: Record<string, Value>) { return process('/visitors/owner_sellers/new', params, []); }
export function visitorsIndustryPath(params: Params<'id'>) { return process('/visitors/industries/' + params.id + '', params, ['id']); }
export function railsServiceBlobPath(params: Params<'signedId'>) { return process('/rails/active_storage/blobs/redirect/' + params.signedId + '/*filename', params, ['signedId']); }
export function railsServiceBlobProxyPath(params: Params<'signedId'>) { return process('/rails/active_storage/blobs/proxy/' + params.signedId + '/*filename', params, ['signedId']); }
export function railsBlobRepresentationPath(params: Params<'signedBlobId' | 'variationKey'>) { return process('/rails/active_storage/representations/redirect/' + params.signedBlobId + '/' + params.variationKey + '/*filename', params, ['signedBlobId','variationKey']); }
export function railsBlobRepresentationProxyPath(params: Params<'signedBlobId' | 'variationKey'>) { return process('/rails/active_storage/representations/proxy/' + params.signedBlobId + '/' + params.variationKey + '/*filename', params, ['signedBlobId','variationKey']); }
export function railsDiskServicePath(params: Params<'encodedKey'>) { return process('/rails/active_storage/disk/' + params.encodedKey + '/*filename', params, ['encodedKey']); }
export function updateRailsDiskServicePath(params: Params<'encodedToken'>) { return process('/rails/active_storage/disk/' + params.encodedToken + '', params, ['encodedToken']); }
export function railsDirectUploadsPath(params?: Record<string, Value>) { return process('/rails/active_storage/direct_uploads', params, []); }
