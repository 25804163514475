import $ from 'jquery'

const combineTableCells = (target) => {
  let $firstCompanyCell = null
  let prevCellText = ''
  let count = 1
  $(target).each((i, td) => {
    const $td = $(td)
    const currentCellText = $td.text()
    if (currentCellText === prevCellText) {
      $td.remove()
      count += 1
    } else {
      if ($firstCompanyCell != null) {
        $firstCompanyCell.prop('rowspan', count)
      }
      count = 1
      $firstCompanyCell = $td
    }
    prevCellText = currentCellText
  })
}

// NOTE: 今回は一旦無効化
// $(document).on('turbo:load', () => {
//   combineTableCells('.js-deal-company-cell')
//   combineTableCells('.js-deal-business-cell')
// })
