import { Controller } from "@hotwired/stimulus"
import toastr from 'toastr'

// Connects to data-controller="flash"
export default class extends Controller {
  static values = {
    type: String,
    message: String
  }
  declare readonly typeValue: string
  declare readonly messageValue: string

  connect() {
    if(this.typeValue == 'notice') {
      toastr.success(this.messageValue)
    } else if(this.typeValue == 'alert') {
      toastr.error(this.messageValue)
    } else {
      toastr.info(this.messageValue)
    }
  }
}
