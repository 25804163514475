import $ from 'jquery'

let timeoutId
const launchBuyerDashboardModal = () => {
  const $modal = $('.js-buyer-dashboard-modal')
  if ($modal.length === 0) return

  $('.js-buyer-dashboard-modal').modal('show')
  const url = $modal.data('auto-redirect-url')
  if (url) {
    timeoutId = setTimeout(() => {
      location.href = url
    }, 6000)
  }
  $('.js-buyer-dashboard-modal').on('hide.bs.modal', () => clearTimeout(timeoutId))
}

$(document).on('turbo:load', launchBuyerDashboardModal)
