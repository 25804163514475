import { enableElement } from '@rails/ujs'

const validateRequestForm = () => {
  const form = document.querySelector('.js-sellers-terms-of-acquisitions-notification-request-form')
  if (form === null) {
    return
  }
  form.addEventListener('submit', (event) => {
    const textarea = event.currentTarget.querySelector('textarea')
    const file = event.currentTarget.querySelector('input[name="notification_request[file]"]')
    if (textarea.value === '' && file.value === '') {
      alert('テキストかファイルのいずれかを入力して下さい')
      event.preventDefault()
      // NOTE: 遅延させないとだめなようなので
      setTimeout(() => {
        enableElement(form)
      }, 500)
    }
  })
}

document.addEventListener('turbo:load', validateRequestForm)
