import $ from 'jquery'
import { find } from 'underscore'

$(document).on('turbo:load', () => {
  const isFileEmpty = (container) => {
    const $container = $(container)
    return (
      ($container.find('input').val() === '' || undefined) &&
      $container.data('attachment-present') === undefined
    )
  }
  const displayOnlyLastEmptyFileInput = (messageContainer) => {
    const $attachments = $(messageContainer).find('.js-attachment')
    $attachments.filter((_, elm) => isFileEmpty(elm)).hide()
    find($attachments, (attachmentContainer) => {
      const $container = $(attachmentContainer)
      $container.show()
      return isFileEmpty($container)
    })
  }
  $('.js-toggle-message-form-link').on('click', (event) => {
    event.preventDefault()
    displayOnlyLastEmptyFileInput($(event.target).closest('.js-message-container'))
    const $target = $(event.target)
    const messageId = $target.data('message-id')
    const $body = $(`#message-detail-${messageId} .message__body`)
    const $form = $(`#message-detail-${messageId} .message__form-container`)
    if ($body.is(':visible')) {
      $target.text('キャンセル')
      $body.hide()
      $form.show()
    } else {
      $target.text('編集')
      $body.show()
      $form.hide()
    }
  })
  $('.js-attachment input').on('change', (event) => {
    displayOnlyLastEmptyFileInput($(event.target).closest('.js-message-container'))
  })
  displayOnlyLastEmptyFileInput($('.js-new-message'))
})

