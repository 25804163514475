import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="hidden"
export default class extends Controller {
  static values = { trigger: String, match: Boolean };
  static targets = ['input', 'hideable'];

  declare readonly triggerValue: string;
  declare readonly matchValue: boolean;
  declare readonly inputTarget: HTMLSelectElement | HTMLInputElement;
  declare readonly inputTargets: HTMLInputElement[];
  declare readonly hideableTargets: HTMLElement[];

  connect() {
    this.updateHideable()
  }

  updateHideable() {
    const inputValue = this.inputTarget.type == 'radio' ? this.inputTargets.find((input) => input.checked)?.value : this.inputTarget.value

    this.hideableTargets.forEach((element) => {
      if (this.matchValue) {
        element.classList.toggle('d-none', inputValue === this.triggerValue)
      } else {
        element.classList.toggle('d-none', inputValue !== this.triggerValue)
      }
    })
  }
}
