const onLoad = (): void => {
  document.querySelectorAll<HTMLElement>('.js-insert-mention').forEach((elm) => {
    elm.addEventListener('click', (event) => {
      event.preventDefault()
      const textarea = document.querySelector<HTMLTextAreaElement>(elm.dataset.insertMentionTarget)
      textarea.value = `${textarea.value} @`
      textarea.dispatchEvent(new CustomEvent('input', { bubbles: true }))
      textarea.focus()
    })
  })
}

document.addEventListener('turbo:load', onLoad)
