import Sortable from 'sortablejs'
import { csrfToken } from '@rails/ujs'

const fetchWithCsrfToken = (url: string, options: RequestInit = {}) => {
  const csrfHeaders = {
    'X-CSRF-Token': csrfToken(),
  }
  const headers = { ...csrfHeaders, ...options.headers }
  return fetch(url, { ...options, headers })
}

const startSortable = () => {
  const sorableContainer = document.querySelector<HTMLElement>('.js-sortable')
  if (sorableContainer === null) return

  new Sortable(
    sorableContainer,
    {
      direction: 'horizontal',
      animation: 100,
      onUpdate: (event) => {
        const { sortableUrl } = event.item.dataset
        fetchWithCsrfToken(
          sortableUrl,
          {
            method: 'PATCH',
            credentials: 'same-origin',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              position: event.newIndex + 1,
            }),
          },
        )
      },
    },
  )
}

document.addEventListener('turbo:load', startSortable)

const toggleStatusForm = (event: MouseEvent) => {
  event.preventDefault()

  const target = event.currentTarget as HTMLElement
  const container = target.closest('.js-match-status-form-container')
  container.querySelectorAll('.js-match-status-views').forEach(el => el.classList.toggle('hidden'))
  container.querySelector('.js-match-status-form').classList.toggle('hidden')
}

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.js-match-status-form-toggle').forEach((elm: HTMLAnchorElement) => {
    elm.addEventListener('click', toggleStatusForm)
  })
})
