import $ from 'jquery'

const showStatusUpdateForm = () => {
  if (location.hash === '#update-status') {
    $('#js-sellers-deal-update-status-form').modal('show')
  }
}

const onChangeStatus = () => {
  const isAborted = $('.js-update-deal-status-select').val() === '交渉終了'
  $('.js-update-deal-status-details').toggleClass('hidden', isAborted)
  $('.js-update-deal-status-aborted-reason').toggleClass('hidden', !isAborted)
}

const onChangeBusinessSelect = (event) => {
  const businessId = $(event.currentTarget).val()
  $.get(`/sellers/businesses/${businessId}/deals.js`)
}

const onNdaSubmitClicked = (event) => {
  if ($('.js-sellers-nda-confirmation-check:checked').val() != '1') {
    alert('確認のチェックボックスを入れてください')
    event.preventDefault()
    event.stopPropagation()
    return
  }
  if (!confirm($('.js-sellers-nda-submit').data('confirmationMessage'))) {
    event.preventDefault()
    event.stopPropagation()
  }
}

const onChangeSelectedBusiness = () => {
  const businessId = $('.js-sellers-business-select').val()
  const $button = $('.js-sellers-show-selected-business-button')
  $button.toggleClass('hidden', !businessId)
  $button.data('url', `/sellers/businesses/${businessId}?no_toolbar=1&no_toa=1`)
}

const onTurboLoad = (event) => {
  showStatusUpdateForm(event)
  $('.js-sellers-update-deal-status-business-select').on('change', onChangeBusinessSelect)
  $('.js-update-deal-status-select').on('change', onChangeStatus)
  $('.js-sellers-nda-submit').on('click', onNdaSubmitClicked)
  $('.js-sellers-business-select').on('change', onChangeSelectedBusiness)
  onChangeSelectedBusiness()
}

document.addEventListener('turbo:load', onTurboLoad)
