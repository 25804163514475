import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disabled"
export default class extends Controller {
  static targets = ['triggerInput', 'disabledInput']
  static values = {
    trigger: String
  }

  declare disabledInputTarget: HTMLInputElement;
  declare triggerInputTargets: HTMLInputElement[];
  declare triggerValue: string;

  connect() {
    this.toggle()
  }

  toggle(): void {
    const triggerInput = this.triggerInputTargets.find(input => input.checked)
    this.disabledInputTarget.disabled = triggerInput.value == this.triggerValue
  }
}
