const onTurboLoad = (): void => {
  document
    .querySelectorAll<HTMLSelectElement>('.js-crm-business-copy-select')
    .forEach((selectElm) => {
      selectElm.addEventListener('change', (event: any) => {
        const { target } = event
        const businessId = target.querySelector('option:checked').value
        const button = document.querySelector<HTMLButtonElement>('.js-crm-business-copy-button')
        button.setAttribute('disabled', 'disabled')
        window.location.href = businessId
      })
    })
}

document.addEventListener('turbo:load', onTurboLoad)
