import { Controller } from "@hotwired/stimulus"
import {
  get,
  create,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON
} from "@github/webauthn-json/browser-ponyfill";
import { post } from '@rails/request.js'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="webauthn"
export default class extends Controller {
  static values = { registrationLinkToken: String }
  declare registrationLinkTokenValue: string

  async register() {
    const registrationResponse = await post('/sellers/webauthn/registrations', {
      contentType: 'application/json',
      responseKind: 'json',
      body: JSON.stringify({ token: this.registrationLinkTokenValue })
    })
    const json = await registrationResponse.response.json()

    const credential = await create(parseCreationOptionsFromJSON({ publicKey: json }))
    const callbackResponse = await post('/sellers/webauthn/registrations/callback', {
      body: JSON.stringify(credential),
    })

    if (callbackResponse.ok) {
      Turbo.visit( '/sellers/webauthn/sessions/new')
    } else {
      alert('登録に失敗しました')
    }
  }

  async signIn() {
    const registrationResponse = await post('/sellers/webauthn/sessions', {
      contentType: 'application/json',
      responseKind: 'json',
    })
    const json = await registrationResponse.response.json()

    const credential = await get(parseRequestOptionsFromJSON({ publicKey: json }))
    const callbackResponse = await post('/sellers/webauthn/sessions/callback', {
      body: JSON.stringify(credential),
    })

    if (callbackResponse.ok) {
      Turbo.visit('/')
    } else {
      alert('認証に失敗しました')
    }
  }
}
