import { TurboFrameRenderEvent } from '@hotwired/turbo'
import { error, success } from 'toastr'

type FlashData = {
  notice: string
  alert: string
}

document.addEventListener('turbo:before-fetch-response', (event: Event) => {
  const json = (event as TurboFrameRenderEvent).detail.fetchResponse.header('X-Flash-Messages')
  if (!json) return

  const flashData: FlashData = JSON.parse(json)

  if (flashData.notice) success(flashData.notice)
  if (flashData.alert) error(flashData.alert)
})
