import { Turbo } from '@hotwired/turbo-rails'

const onClickCell = (event) => {
  const input: HTMLInputElement = (event.target as HTMLElement).querySelector('input')
  if (input === null) return
  input.checked = !input.checked
  event.stopPropagation()
}

const onClickBulkMessageButton = (event) => {
  event.preventDefault()
  const checkboxes = document.querySelectorAll('input.js-deal-checkbox:checked')
  const ids: string[] = Array.from(checkboxes).map((checkbox: HTMLInputElement) => checkbox.value)
  if (ids.length > 0) {
    Turbo.visit(`/sellers/bulk_messages/new?${ids.map((id) => `deal_ids[]=${id}`).join('&')}`)
  } else {
    alert('メッセージを選択してください')
  }
}

const onLoad = (event) => {
  const cells = document.querySelectorAll('.js-deal-checkbox-cell')
  if (cells.length === 0) return

  cells.forEach((cell) => {
    cell.addEventListener('click', onClickCell)
  })

  const button = document.querySelector('.js-bulk-message-button')
  if (button) {
    button.addEventListener('click', onClickBulkMessageButton)
  }
}

document.addEventListener('turbo:load', onLoad)
