import $ from 'jquery'
import select2 from 'select2'

select2()

$(document).on('turbo:load', () => {
  $('.js-select2').each((i, select2Elm) => {
    // NOTE: modal 上の select2 にも対応するために dropdownParent を設定する
    const $select2 = $(select2Elm)
    const modalContainer = $select2.closest('.modal')
    const dropdownParent = modalContainer.length === 0 ? null : modalContainer
    const width = $select2.data('select2-width') || '100%'
    const tags = $select2.data('select2-tags') || false

    $select2.select2({
      dropdownParent,
      tags,
      width,
    })
  })
})
