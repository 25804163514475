import $ from 'jquery'

const onLoadReadmore = (event) => {
  const $target = $('.js-readmore')
  // NOTE: css に分離しようかと思ったけど、JSで完結したいのであえてここで
  $target.css('height', '80px')
  $target.css('overflow', 'hidden')
  $target.css('text-overflow', 'ellipsis')
  $target.css('position', 'relative')
  $target.css('padding-bottom', '100px')

  const $link = $(
    '<div class="js-readmore-link-container"><a class="js-readmore-link" href="#">全て読む</a></div>',
  )
  $link.css('position', 'absolute')
  $link.css('top', '80px')
  $link.css('background-color', 'white')
  $link.css('width', '100%')
  $target.append($link)

  $target.find('.js-readmore-link').on('click', onClickReadmore)
}

const onClickReadmore = (event) => {
  event.preventDefault()
  const $link = $(event.currentTarget)
  const $container = $link.closest('.js-readmore')
  $container.css('height', 'auto')
  $container.css('position', 'static')
  $container.css('padding-bottom', 0)
  $container.find('.js-readmore-link-container').remove()
}

$(document).on('turbo:load', onLoadReadmore)
