import SmoothScroll from 'smooth-scroll'
import $ from 'jquery'

$(document).on('click', '.js-smooth-scroll', (event) => {
  const href = $(event.currentTarget).prop('href')
  const anchorTexts = href.match(/(#.*)/)
  if (anchorTexts !== null && anchorTexts.length > 0) {
    event.preventDefault()
    event.stopPropagation()
    const anchor = document.querySelector(anchorTexts[1])
    const scroll = new SmoothScroll('.js-smooth-scroll', {
      speed: 1500,
      easing: 'easeInOutQuart',
    })
    scroll.animateScroll(anchor)
  }
})
